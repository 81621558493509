import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { KeyboardAction, KeyboardService, Lab, LoggerService, ModalContainerService } from '@lims-common-ux/lux';

import { AppStateService } from '../../app-state.service';
import { CurrentRunPipe } from '../current-run.pipe';
import { RepeatRunComponent } from '../repeat-run/repeat-run.component';
import { RunPickerComponent } from '../run-picker/run-picker.component';
import { CBCAccession, Run } from '../workspace-accession.service';
import { ManualDiffComponent } from '../manual-diff/manual-diff.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @Input() hasChanges: boolean;

  lab: Lab;
  accession: CBCAccession;

  @Output()
  handleNextInQueueClick: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('repeatRun', { static: false })
  repeatRunComponent: RepeatRunComponent;

  @ViewChild('runPicker', { static: false })
  runPicker: RunPickerComponent;

  @ViewChild('manualDiff', { static: false })
  manualDiff!: ManualDiffComponent;

  currentRun: number;
  runs: Run[];
  accessionSub: Subscription;

  focusRunPickerAction = {
    name: 'focus-run-picker-button',
    eventMatch: { key: 'p', altKey: true },
    matchCallback: () => {
      if (this.modalService.openModal) {
        return;
      }
      if (this.runs.length > 1) {
        this.runPicker.focusRunPickerButton();
      }
    },
  } as KeyboardAction;

  repeatRunAction = {
    name: 'repeat-assay',
    matchCallback: ($evt) => {
      $evt.preventDefault();
      $evt.stopImmediatePropagation();
      const currentRunData = this.currentRunPipe.transform(this.runs, this.currentRun);
      const currentRunIsRepeatable = currentRunData?._links?.repeatRun?.href;
      if (!this.hasChanges && currentRunIsRepeatable) {
        this.repeatRunComponent.repeatRun();
      }
    },
  } as KeyboardAction;

  constructor(
    private keyboardService: KeyboardService,
    private currentRunPipe: CurrentRunPipe,
    private modalService: ModalContainerService,
    private appStateService: AppStateService,
    private loggerService: LoggerService
  ) {}

  ngOnInit() {
    this.keyboardService.addActions([this.focusRunPickerAction, this.repeatRunAction]);

    this.accessionSub = this.appStateService.accession$.subscribe((val) => {
      this.accession = val;

      if (this.manualDiff?.visible) {
        this.manualDiff.closeModal(false, !!val);
      }

      this.runs = this.accession?.runs || [];
      this.currentRun = this.accession?.currentRun || null;

      setTimeout(() => {
        if (this.accession?.runMustBePicked) {
          this.loggerService.logAction('run-must-be-picked', { accession: this.appStateService.accession as any });
          this.runPicker.openModal();
        } else {
          // When navigating through a queue, close the modal
          this.runPicker.closeModal();
        }
      }, 0);
    });

    this.lab = this.appStateService.lab;
  }

  nextInQueue() {
    this.runPicker.closeModal();
    requestAnimationFrame(() => {
      this.handleNextInQueueClick.emit();
    });
  }

  ngOnDestroy() {
    if (this.accessionSub) {
      this.accessionSub.unsubscribe();
    }
    this.keyboardService.removeAction(this.focusRunPickerAction);
    this.keyboardService.removeAction(this.repeatRunAction);
  }
}
