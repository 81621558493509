import { Component, HostListener } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {
  GlobalErrorHandlerService,
  KeyboardService,
  Lab,
  LoggerService,
  ModalContainerService,
  ResultsDataResourcesService,
  User,
  Workspace,
  WorkspacesService,
} from '@lims-common-ux/lux';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from './app-state.service';
import { Accession } from '@lims-common-ux/lux/lib/accession/accession.interface';
import { Event, GuardsCheckEnd, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  workspaces: Observable<Workspace[]>;
  labs: Lab[];
  lab: Observable<Lab>;
  accession: Observable<Accession>;
  user: Observable<User>;
  private onDestroy$ = new Subject<void>();
  loggedIn = false;

  @HostListener('document:keydown', ['$event'])
  onKeydown($evt: KeyboardEvent) {
    // Prevent keyboard interactions while loading
    if (this.appStateService.loading) {
      this.keyboardService.preventDefaultAndPropagation($evt);
      return false;
    }

    // Modals handle their own key events
    if (!!this.modalService.openModal) {
      return $evt;
    }

    if (!this.appStateService.loading) {
      this.keyboardService.handleAppKeydown($evt);
    }
  }

  constructor(
    public keyboardService: KeyboardService,
    private resultsDataResourcesService: ResultsDataResourcesService,
    private workspacesService: WorkspacesService,
    public appStateService: AppStateService,
    private translate: TranslateService,
    private router: Router,
    private modalService: ModalContainerService,
    private authService: MsalService,
    private broadcastService: MsalBroadcastService,
    private title: Title,
    private loggerService: LoggerService,
    private globalErrorHandlerService: GlobalErrorHandlerService
  ) {
    this.router.events.subscribe((e: Event) => {
      if (e instanceof NavigationStart) {
        this.appStateService.loading = true;
      } else if (e instanceof NavigationEnd || (e instanceof NavigationError && this.appStateService.accession)) {
        if (e instanceof NavigationError && this.appStateService.accession) {
          this.loggerService.logError('accession-loaded-error', { accession: this.appStateService.accession as any });
        }
        this.appStateService.loading = false;
      } else if (e instanceof GuardsCheckEnd && !e.shouldActivate) {
        this.loggerService.logAction('route-guard-error', { event: e });
        this.appStateService.loading = false;
      } else if (e instanceof GuardsCheckEnd && e.shouldActivate) {
        // we don't cancel loading here as it should fall to navigation end
        this.loggerService.logAction('application-loaded', {});
        this.appStateService.accessionHeader = null;
        this.appStateService.accession = null;
      }
    });

    this.globalErrorHandlerService.errors.subscribe((errorResource) => {
      this.loggerService.logError(errorResource, { accessionId: this.appStateService.accession?.accessionId });
    });

    this.title.setTitle(this.appStateService.defaultPageTitle);

    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;

    this.broadcastService.inProgress$
      .pipe(
        filter((progress) => {
          return progress === InteractionStatus.None;
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => {
        this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
      });
  }

  handleAccessionRefresh() {
    window.location.reload();
  }
}
