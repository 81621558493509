import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NgForm } from '@angular/forms';

import {
  KeyboardAction,
  KeyboardService,
  Lab,
  LabNotesComponent,
  LabNotesService,
  Link,
  ModalContainerService,
} from '@lims-common-ux/lux';
import { Accession } from '@lims-common-ux/lux/lib/accession/accession.interface';

import { catchError, filter, NEVER, of, Subject, switchMap, takeUntil, throwError } from 'rxjs';

import { AppStateService } from '../../app-state.service';
import { CanDeactivateService } from '../../can-deactivate/can-deactivate.service';
import { Assay } from '../../interfaces/assay.interface';
import { Panel } from '../../panel/panel.interface';
import { AssayCardComponent } from '../assay/assay-card/assay-card.component';
import { AssayWrapperService } from '../assay/assay-wrapper.service';
import { AssayDetailsComponent } from '../assay/assay-details/assay-details.component';
import { AccessionService, ConflictError } from '../accession/accession.service';
import { AssayCategory, CBCAccession, WorkspaceAccessionService } from '../workspace-accession.service';
import { WorkspaceQueueService } from '../workspace-queue.service';
import { AssayLineItemComponent } from '../assay/assay-line-item/assay-line-item.component';
import { WorkspaceConfigService } from '../workspace/workspace-config.service';

@Component({
  templateUrl: './accession.component.html',
  styleUrls: ['./accession.component.scss'],
})
export class AccessionComponent implements OnInit, OnDestroy, AfterViewInit {
  addLink = {
    href: 'https://exp.clouded-leopard.idexx.com/lab-notes/api/lab-notes/accession/{accessionId}',
    type: 'POST',
    templated: true,
  } as Link;

  getLink = {
    href: 'https://exp.clouded-leopard.idexx.com/lab-notes/api/lab-notes/accession/{accessionId}',
    templated: true,
  } as Link;

  @ViewChildren('assayWrapper')
  assayWrappers!: QueryList<AssayCardComponent> | QueryList<AssayLineItemComponent>;

  @ViewChild('assayDetails', { static: false })
  assayDetails: AssayDetailsComponent;

  @ViewChild('labNotes')
  labNotes: LabNotesComponent;

  @ViewChild('form')
  form: NgForm;

  accession: CBCAccession;
  headerAccession: Accession;

  assays: Assay[];
  panels: Panel[];

  currentAssayWrapper: AssayCardComponent | AssayLineItemComponent;
  hasNewPanelComments = false;
  haveAcceptedPanels = false;
  acceptPanelTouched = false;
  lab: Lab;

  saveButtonEnabled = false;
  acceptButtonEnabled = false;

  assayWrapperPresentationReady: boolean;

  nonCellLineAssays: Assay[];
  cellLineAssays: Assay[];
  observationAssays: Assay[];

  private focusFirstAssayAction: KeyboardAction = {
    name: 'assay-first-focus',
    matchCallback: ($evt: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation($evt);
      this.focusFirstAssay();
    },
  };

  private focusAssayCommentAction: KeyboardAction = {
    name: 'focus-assay-comment',
    matchCallback: ($evt: KeyboardEvent) => {
      $evt.preventDefault();
      $evt.stopImmediatePropagation();

      if (this?.assayDetails?.selectedAssay && this.assayDetails.selectedAssay.canModify) {
        this.assayDetails.focusResultComment();
      }
    },
  };

  focusPanelCommentAction: KeyboardAction = {
    name: 'focus-panel-comment',
    matchCallback: ($evt: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation($evt);

      if (!this.panels || this.panels?.length < 1 || !this.panels[0].canModify) {
        return;
      }

      this.focusPanelComment();
    },
  };

  focusPanelAcceptAction: KeyboardAction = {
    name: 'focus-accept-panel',
    eventMatch: { key: 'F10' },
    matchCallback: (event: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation(event);

      if (!this.configService.acceptPanelsEnabled || !this.panels.some((panel) => panel.acceptable)) {
        return;
      }
      this.appStateService.currentAssay = null;

      // The async updates here trigger appState changes, a screen paint to show hidden UI components, and
      // change browser focus. Either requestAnimationFrame or setTimeout is used to ensure the event loop
      // is resolved before attempting focus.
      requestAnimationFrame(() => {
        this.assayDetails.panelAccept?.focusFirstPanel();
      });
    },
  };

  private acceptAssayAction = {
    name: 'accept-all-assay-results',
    matchCallback: ($evt: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation($evt);
      this.saveAssays(true);
    },
  } as KeyboardAction;

  private saveAction = {
    name: 'save-assays',
    eventMatch: {
      key: 's',
      altKey: true,
      matcher: (event: KeyboardEvent) => !this.modalService.openModal,
    },
    matchCallback: ($evt) => {
      if (this.labNotes?.visible) {
        $evt.preventDefault();
        this.labNotes.addLabNote();
      } else {
        this.saveAssays();
      }
    },
  } as KeyboardAction;

  private noResultAssayAction = {
    name: 'no-result-assay',
    matchCallback: ($evt) => {
      this.keyboardService.preventDefaultAndPropagation($evt);
      if (!this.currentAssayWrapper?.assay.updatedResult.noResult && this.assayDetails.selectedAssay.canModify) {
        this.handleNoResult();
      }
    },
  } as KeyboardAction;

  // See run-picker.component for modal open implementation
  nextInQueueAction = {
    name: 'next-in-queue',
    eventMatch: {
      key: 'ArrowRight',
      altKey: true,
      matcher: () => !this.modalService.openModal,
    },
    matchCallback: ($evt: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation($evt);
      this.nextInQueue();
    },
  } as KeyboardAction;

  nextAssayCategoryAction = {
    name: 'next-assay-category',
    eventMatch: {
      key: 'ArrowDown',
      altKey: true,
      matcher: ($evt: KeyboardEvent) => !this.modalService.openModal,
    },
    matchCallback: ($evt: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation($evt);
      const currentAssayCategory = this.appStateService.currentAssay?.category;
      this.navigateAssayCategory(currentAssayCategory, true);
    },
  } as KeyboardAction;

  previousAssayCategoryAction = {
    name: 'previous-assay-category',
    eventMatch: {
      key: 'ArrowUp',
      altKey: true,
      matcher: ($evt: KeyboardEvent) => !this.modalService.openModal,
    },
    matchCallback: ($evt: KeyboardEvent) => {
      this.keyboardService.preventDefaultAndPropagation($evt);
      const currentAssayCategory = this.appStateService.currentAssay?.category;
      this.navigateAssayCategory(currentAssayCategory);
    },
  } as KeyboardAction;

  private keyboardActions = [
    this.saveAction,
    this.noResultAssayAction,
    this.focusAssayCommentAction,
    this.acceptAssayAction,
    this.nextInQueueAction,
    this.nextAssayCategoryAction,
    this.previousAssayCategoryAction,
    this.focusFirstAssayAction,
    this.focusPanelCommentAction,
    this.focusPanelAcceptAction,
  ];

  private onDestroy$ = new Subject<void>();

  // Because we are using listener on a global app state, and using setTimeout() to fire changes, we can get
  // into a situation where the component is being destroyed, but the timeout is still called and tries
  // to act on an invalid component state. This flag will simply let other parts of the component know that they
  // should not be acting any longer
  private destroying = false;

  // will trigger in the case where we have unsaved changes and
  // we route to an address outside of Angular (ex: 401 redirects for sso)
  // we cannot rely on the guard in this case because we'd be routing outside of our app
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = true;
    }
  }

  constructor(
    private workspaceAccessionService: WorkspaceAccessionService,
    private workspaceQueueService: WorkspaceQueueService,
    private assayWrapperService: AssayWrapperService,
    private assayService: AccessionService,
    private canDeactivateService: CanDeactivateService,
    public appStateService: AppStateService,
    private keyboardService: KeyboardService,
    private modalService: ModalContainerService,
    private configService: WorkspaceConfigService,
    private labNotesService: LabNotesService
  ) {}

  canDeactivate(): boolean {
    // False pops a message here
    return !this.saveButtonEnabled;
  }

  assayWrapperClass(assay: Assay): string {
    if (
      assay?.resultDefinition?.valueType === 'SEMI_QUANTITATIVE_COMBO' ||
      assay?.resultDefinition?.valueType === 'DEFINED_MULTI_TEXT'
    ) {
      return 'multi-value-assays';
    } else {
      return '';
    }
  }

  ngOnInit(): void {
    // We want to focus the first presented assay when some external, unrelated component triggers
    // an event emit in the shared appStateService. We also focus the first assay when the
    // accession changes. See the appStateService accession subscription below.
    this.appStateService.focusFirstAssayEvent.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.focusFirstAssay();
    });

    // Accession loaded
    this.appStateService.accession$
      .pipe(
        takeUntil(this.onDestroy$),
        filter((accession) => {
          return accession !== null;
        })
      )
      .subscribe((cbcAccession: CBCAccession) => {
        this.nonCellLineAssays = this.workspaceAccessionService.getNonCellLineAssays();
        this.cellLineAssays = this.workspaceAccessionService.getCellLineAssays();
        this.observationAssays = this.workspaceAccessionService.getObservationAssays();

        this.setAccessionInfo(cbcAccession);
      });

    this.appStateService.currentAssaySub.pipe(takeUntil(this.onDestroy$)).subscribe((updatedAssay) => {
      if (updatedAssay) {
        const currentWrapper = this.assayWrappers?.find((wrapper) => wrapper.assay?.testCode === updatedAssay.testCode);
        this.currentAssayWrapper = currentWrapper;
      }
    });

    this.keyboardService.addActions(this.keyboardActions);
  }

  ngAfterViewInit() {
    this.form.statusChanges.subscribe((newVal) => {
      this.setCanSave();
      this.setCanAccept();
    });
  }

  ngOnDestroy() {
    this.accession = null; // just in case something tries to act on the accession after we destroy the component, error out.
    this.destroying = true;
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.keyboardActions.forEach((action) => {
      this.keyboardService.removeAction(action);
    });
  }

  setAccessionInfo(loaded: CBCAccession) {
    if (!loaded) {
      return;
    }

    let newAccession = true;
    // this gets called when we first land on an accession, or the accession is updated after a save or
    // while navigating the queue. We need to tell the difference to know how to treat our assays.
    if (this.headerAccession && this.headerAccession.id === loaded.accessionId) {
      newAccession = false;
    }
    this.assayWrapperPresentationReady = false;
    this.lab = this.appStateService.lab;
    this.headerAccession = this.appStateService.accessionHeader;
    this.accession = loaded;

    this.assays = loaded.assays;

    this.panels = loaded.panels;
    if (newAccession) {
      this.assayWrapperService.setInitialAssaysAndVersions(this.assays);
    } else {
      this.assayWrapperService.setCurrentAssaysAndVersions(this.assays);
    }

    this.resetFormAndFocus();
    this.acceptPanelTouched = false;
    this.haveAcceptedPanels = this.panels.some((panel) => panel.accept);
  }

  navigateAssayCategory(currentCategory: AssayCategory, forward?: boolean) {
    let focusAssay;
    switch (currentCategory) {
      case AssayCategory.NON_CELL_LINE:
        focusAssay = forward ? this.cellLineAssays[0] : this.observationAssays[0];
        break;
      case AssayCategory.CELL_LINE:
        focusAssay = forward ? this.observationAssays[0] : this.nonCellLineAssays[0];
        break;
      case AssayCategory.OBSERVATION:
        focusAssay = forward ? this.nonCellLineAssays[0] : this.cellLineAssays[0];
        break;
      default:
        // This default case covers the case were no assay or category is selected when a
        // user invokes the category navigation shortcut. This can occur when a user
        // is adding comments to panels, for instance
        focusAssay = this.nonCellLineAssays[0];
        break;
    }
    this.setCurrentAssay(focusAssay);
    this.showAssayCards();
  }

  private resetFormAndFocus() {
    setTimeout(() => {
      if (!this.destroying) {
        this.hasNewPanelComments = false;
        this.appStateService.loading = false;
        this.focusFirstAssay();
        this.setPristine();
      }
    }, 0);
  }

  getAbsoluteAssay(cellLineAssay: Assay) {
    const associatedAbsoluteAssayId = this.workspaceAccessionService.getCellLineAssociationId(cellLineAssay);
    return this.assays.find((assay) => assay.standardIdexxAssay === associatedAbsoluteAssayId);
  }

  focusFirstAssay() {
    setTimeout(() => {
      if (!this.destroying && this.assays.length) {
        const firstNonCellLineAssay = this.nonCellLineAssays && this.nonCellLineAssays[0];
        const firstCellLineAssay = this.cellLineAssays && this.cellLineAssays[0];
        const firstObservationAssay = this.observationAssays && this.observationAssays[0];

        const selectedAssay = firstNonCellLineAssay || firstCellLineAssay || firstObservationAssay;
        this.setCurrentAssay(selectedAssay);
        // To prevent visual flash of intermediate presentation states, delay assay visibility until styles are ready
        this.showAssayCards();
      }
    }, 0);
  }

  focusPanelComment() {
    this.appStateService.currentAssay = null;

    // The async updates here trigger appState changes, a screen paint to show hidden UI components, and
    // change browser focus. Either requestAnimationFrame or setTimeout is used to ensure the event loop
    // is resolved before attempting focus.
    requestAnimationFrame(() => {
      this.assayDetails.panelsView?.panelComment?.focusSearchInput();
    });
  }

  showAssayCards() {
    this.assayWrapperPresentationReady = true;
    this.currentAssayWrapper?.selectResultInput();
  }

  setCurrentAssay(assay: Assay) {
    const currentAssayWrapper = this.assayWrappers.find((wrapper) => wrapper.assay.testCode === assay.testCode);

    this.appStateService.currentAssay = currentAssayWrapper?.assay;

    currentAssayWrapper?.selectResultInput();
  }

  // Does not include defined text inputs?
  // This method navigates to the next assay assay following valid result input, no result
  handleValueChange() {
    let next;
    this.assayWrappers.forEach((wrapper, index) => {
      if (this.currentAssayWrapper && this.currentAssayWrapper.assay.testCode === wrapper.assay.testCode) {
        next = index + 1;
      }

      if (next === index) {
        setTimeout(() => {
          if (wrapper.resultInput) {
            wrapper.resultInput.focusInput();
          } else {
            wrapper.displayValueLink?.nativeElement?.focus();
          }
        }, 0);
      }
    });
  }

  saveAssays(technicallyAccept: boolean = false) {
    if (
      (!technicallyAccept && !this.saveButtonEnabled) ||
      (technicallyAccept && !this.acceptButtonEnabled) ||
      this.labNotes?.hasUnsavedLabNotes
    ) {
      return;
    }
    this.assayWrapperPresentationReady = false;
    this.appStateService.loading = true;
    this.assayService
      .saveAssays(this.accession, this.assays, technicallyAccept, this.panels)
      .pipe(
        catchError((e) => {
          this.form.form.setErrors({ saveError: true });
          if (e instanceof ConflictError) {
            return NEVER;
          } else {
            // Hide the loading screen since we want the user to be able to close the dialog and continue on in these
            // scenarios. Conflict should always require a reload....but I think we should revisit that to allow the
            // user to view their work and open the accession in another tab to continue
            this.appStateService.loading = false;
            this.assayWrapperPresentationReady = true;
            return throwError(e);
          }
        }),
        switchMap(() => {
          // reload accession with updated assays
          return this.workspaceAccessionService.loadWorkspaceAccession(
            this.appStateService.accessionHeader,
            this.appStateService.currentWorkspace
          );
        })
      )
      .subscribe();
  }

  handleNoResult() {
    // Assays are @Input to AssayCardComponent || AssayLineItemComponent, so updating the assay via this.assays will automatically
    // trigger change detection.
    this.assayService.noResult(this.findCurrentAssay(), () => {
      this.handleNonValueResultUpdates();
    });
  }

  handleNonValueResultUpdates() {
    // don't loose the assay since we move to a timeout, and something could loose focus on the assay.
    setTimeout(() => {
      this.currentAssayWrapper.markAsTouchedDirty();
      this.form.form?.markAsDirty();
      this.handleValueChange();
      this.setCanAccept();
      this.setCanSave();
    }, 0);
  }

  // Catches emit from assay comment add in details view
  handleAssayUpdated() {
    this.currentAssayWrapper.markAsTouchedDirty();
    this.currentAssayWrapper.setCardPresentation();
    this.setCanSave();
    this.setCanAccept();
  }

  // Mark the form dirty here to allow save or accept with only a panel comment update
  handlePanelCommentsUpdated($event) {
    this.hasNewPanelComments = true;
    this.form.form?.markAsDirty();
    this.setCanSave();
    this.setCanAccept();
  }

  handlePanelAcceptUpdated() {
    this.acceptPanelTouched = true;
    this.haveAcceptedPanels = this.panels.some((panel) => panel.accept);
    this.setCanSave();
    this.setCanAccept();
  }

  setPristine() {
    if (this.form?.form) {
      this.form?.form.markAsPristine(); // makes sure form is set to initial/untouched state after assays load
    }

    this.assayWrappers.forEach((wrapper) => {
      wrapper.markAsPristineUntouched();
      wrapper.setCardPresentation();
    });

    this.setCanSave();
    this.setCanAccept();
    this.focusFirstAssay();
  }

  // Details View (not assay wrapper) escape key handling
  handleDetailsEscape($event?) {
    if (this.currentAssayWrapper) {
      if ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      }
      this.currentAssayWrapper.selectResultInput();
    }
  }

  // The save button is enabled when:
  // The accession has assays that have valid result changes
  // The accession has assays assays or panels with comment updates
  setCanSave() {
    setTimeout(() => {
      this.saveButtonEnabled =
        (this.hasNewPanelComments || this.form.dirty || this.haveAcceptedPanels || this.acceptPanelTouched) &&
        this.form.valid;

      // Observable required here for canDeactivate route guard
      this.appStateService.hasSavableChanges = of(this.saveButtonEnabled);
    }, 0);
  }

  // The accept button is enabled when:
  // The accession has assays that are 'saved' but not yet accepted
  // The accession has assays with valid changes
  setCanAccept() {
    setTimeout(() => {
      const hasAcceptableAssays = this.assays.filter((assay) => {
        return assay.status === 'RESULT_RECEIVED' || assay.status === 'TECHNICIAN_REVIEW';
      });

      this.acceptButtonEnabled = (hasAcceptableAssays?.length > 0 || this.form.dirty) && this.form.valid;
    }, 0);
  }

  nextInQueue() {
    if (this.destroying || !this.appStateService.queueWorkspace || this.labNotes?.hasUnsavedLabNotes) {
      // either on the empty queue page, or not in the queue, so do nothing.
      return;
    }
    const goToNext = this.canDeactivateService.unsavedChangesAlert(this.saveButtonEnabled);
    if (goToNext && !this.appStateService.loading) {
      // we explicitly clear accession information
      // since we dont change the url
      this.appStateService.accessionHeader = null;
      this.appStateService.accession = null;
      this.appStateService.currentAssay = null;
      this.appStateService.loading = true;
      this.assays = [];
      this.observationAssays = [];
      this.panels = [];
      this.accession = null;
      this.assayWrapperPresentationReady = false;

      if (this.labNotesService.labNotesOpen) {
        this.labNotes?.closeLabNotesModal();
      }

      this.workspaceQueueService
        .advanceQueue(this.appStateService.workspaceQueueNextUrl)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(() => {
          // accession change is filtered when null, so make sure we mark that we are done loading here.
          this.appStateService.loading = false;
        });
    }
  }

  private findCurrentAssay(): Assay {
    return this.assays.find((assay) => assay.testCode === this.currentAssayWrapper.assay.testCode);
  }
}
